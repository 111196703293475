<template> </template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import store from '@/store';

export default {
  data() {
    return {
      email: localStorage.getItem('email'),
      userData: JSON.parse(localStorage.getItem('userData')),
      profileURL: store.state.app.profileURL + JSON.parse(localStorage.getItem('userData')).profile,
    };
  },

  methods: {
    backRouter() {
      router.go(-1);
    },

    nextRouter() {
      router.go(1);
    },

    navigateProfile() {
      if (router.currentRoute.name != 'user-profile') {
        this.$router.push({ name: 'user-profile' });
      }
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem('userData');

      // Reset ability
      /*
      this.$ability.update(initialAbility);
      */

      // Redirect to login page
      this.$router.push({ name: 'auth-login' });
    },
  },

  computed: {},

  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
