export default [
  {
    header: 'APP MENU',
  },
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },
];
